import "../App.css";
import React, { useState, useEffect, useRef } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import {
  Layout,
  Menu,
  Button,
  Dropdown,
  Space,
  Skeleton,
  Image,
  Divider,
  Typography,
  Card,
} from "antd";
import {
  SettingFilled,
  UserOutlined,
  MoreOutlined,
  LogoutOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth/AuthContext";
import { navObjects } from "../static/navObjects";
import AuthenticationService from "../auth/AuthenticationService";
import logo from "../static/einsnulleins_Signet_RGB.png";
const { Header, Content, Sider, Footer } = Layout;
const { Text } = Typography;

const MainMenu = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();
  const { currentAccount, imageUrl, filteredNavItems } = useAuthContext();
  const [showDetails, setShowDetails] = useState(true);
  const [currentTenant, setCurrentTenant] = useState(currentAccount?.msp);
  const accountURL = AuthenticationService.getAccountUrl();
  const managed_msps = currentAccount?.msp.manages?.map((msp) => ({
    key: msp.id,
    label: msp.name,
  }));

  managed_msps?.push({
    key: currentAccount?.msp.id,
    label: currentAccount?.msp.name,
  });
  const [navitems, setNavitems] = useState();
  const menuRef = useRef(null);

  const userDropdownMenu = [
    {
      label: "Account",
      icon: <SettingFilled />,
      key: "account",
    },
    currentAccount?.roles.includes("hub-admin") && {
      label: "Admin-Konsole",
      icon: <EngineeringIcon />,
      key: "admin-console",
    },
    {
      label: "Abmelden",
      icon: <LogoutOutlined />,
      key: "logout",
    },
  ];

  const MenuPlaceHolder = () => {
    return (
      <div style={{ margin: 20 }}>
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <Skeleton.Button active block />
          <Skeleton.Button active block />
          <Skeleton.Button active block />
          <Skeleton.Button active block />
          <Skeleton.Button active block />
        </Space>
      </div>
    );
  };

  const onUserDropDownClick = ({ key }) => {
    if (key === "logout") {
      AuthenticationService.doLogout();
    }
    if (key === "admin-console") {
      window.open("https://hub.api.einsnulleins.de/admin/", "_blank");
    }
    if (key === "account") {
      window.open(accountURL, "_blank");
    }
  };

  const onCollapseClick = () => {
    setCollapsed(!collapsed);
    setOpenKeys([]);
    setSelectedKeys([]);
    if (collapsed) {
      setTimeout(() => {
        setShowDetails(true);
      }, 100); // adjust this to match the animation duration
    } else {
      setShowDetails(false);
    }
  };

  const handleOpenChange = (openKeys) => {
    setOpenKeys(openKeys);
    setSelectedKeys([]);
  };

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: "#b9e4dfff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: 30,
          }}
        >
          <img
            className="logo"
            src={logo}
            style={{
              height: "45px",
              width: "auto",
              marginRight: "auto",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          ></img>{" "}
          <LogoutOutlined
            style={{
              fontSize: "25px",
              color: "red",
            }}
            onClick={() => AuthenticationService.doLogout()}
          />
        </div>
      </Header>

      <Layout>
        <Sider
          id="sider"
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="menu-sider"
          width={350}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "auto",
              }}
            >
              {filteredNavItems.length === 0 ? (
                <MenuPlaceHolder />
              ) : (
                <Menu
                  ref={menuRef}
                  mode="inline"
                  className="menu"
                  selectedKeys={selectedKeys}
                  openKeys={openKeys}
                  onOpenChange={(openKeys) => handleOpenChange(openKeys)}
                  onSelect={(item) => setSelectedKeys(item.selectedKeys)}
                  items={filteredNavItems}
                  onClick={({ key }) => {
                    navigate(key);
                  }}
                />
              )}
            </div>
            <div></div>
            <div className="collapse-button" onClick={() => onCollapseClick()}>
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </div>
            <div
              style={{
                height: "auto",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider />
              {showDetails ? (
                <div
                  style={{
                    display: "flex",
                    marginBottom: 25,
                    margin: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "2px solid #f0f0f0",
                    padding: 10,
                    borderRadius: 10,
                    background: "#4ab8a863",
                  }}
                >
                  {imageUrl && imageUrl != "fallback" ? (
                    <Image
                      width={50}
                      preview={false}
                      style={{
                        borderRadius: "50%",
                        marginRight: 10,
                      }}
                      src={imageUrl}
                    />
                  ) : imageUrl !== "fallback" ? (
                    <Skeleton.Avatar active size={50} shape="circle" />
                  ) : (
                    <AccountCircleIcon
                      style={{
                        fontSize: 50,
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {currentAccount ? (
                      <>
                        {" "}
                        <Text strong style={{ fontSize: 16 }}>
                          {currentAccount?.full_name}
                        </Text>
                        <Text style={{ fontSize: 10 }}>
                          {currentAccount?.email}
                        </Text>
                      </>
                    ) : (
                      <Skeleton.Input style={{ width: 100 }} active />
                    )}
                  </div>
                  <Dropdown
                    menu={{
                      items: userDropdownMenu,
                      selectable: true,
                      defaultSelectedKeys: [currentTenant?.id],
                      onClick: onUserDropDownClick,
                    }}
                    trigger={["click"]}
                  >
                    <Button icon={<MoreOutlined />} />
                  </Dropdown>
                </div>
              ) : (
                <div
                  style={{
                    margin: 10,
                    marginBottom: 25,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px solid #f0f0f0",
                    padding: 10,
                    borderRadius: 10,
                    background: "#4ab8a863",
                  }}
                >
                  <Dropdown
                    menu={{
                      items: userDropdownMenu,
                      selectable: true,
                      defaultSelectedKeys: [currentTenant?.id],
                      onClick: onUserDropDownClick,
                    }}
                    trigger={["hover"]}
                  >
                    {imageUrl && imageUrl != "fallback" ? (
                      <Image
                        width={50}
                        preview={false}
                        style={{
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                        src={imageUrl}
                      />
                    ) : imageUrl !== "fallback" ? (
                      <Skeleton.Avatar active size={50} shape="circle" />
                    ) : (
                      <AccountCircleIcon
                        style={{
                          fontSize: 50,
                        }}
                      />
                    )}
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </Sider>

        <Layout>
          <Content className="content-container">{children}</Content>
        </Layout>
      </Layout>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        einsnulleins GmbH ©2024 Created and developed by Jonas Twielemeier{" "}
      </Footer>
    </Layout>
  );
};

export default MainMenu;
