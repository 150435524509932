import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../App.css";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import {
  Form,
  message,
  Space,
  Select,
  DatePicker,
  Collapse,
  Modal,
  Result,
  Button,
  Typography,
} from "antd";
import { labels } from "../static/labels";
import { Empty } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import api from "../utils/useAxios";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
const { Title, } = Typography;
const PowerBI = () => {
  const {
    PowerBiReports,
    idpDetails,
    msalInstance,
    initialized,
    powerBiToken,
    setPowerBiToken,
  } = useAuthContext();
  const [messageApi, contextHolder] = message.useMessage();
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [myForm] = Form.useForm();
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedType, setSelectedType] = useState();
  const [reportTypes, setReportTypes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [PowerBIAuthError, setPowerBIAuthError] = useState(false);

  const getReports = async () => {
    try {
      const response = await api.get("powerbi/reports");
      const data = await response.data;
      setReports(data);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const getReportTypes = async () => {
    try {
      const response = await api.get("powerbi/reports/types");
      const data = await response.data;
      const values = data.map((row) => {
        return { ...row, key: row.id };
      });
      setReportTypes(values);
    } catch (error) {
      messageApi.error("Fehler beim Laden der PowerBI Reports");
    }
  };

  const filterReports = () => {
    if (!selectedType || !selectedDate) return;
    const filteredReports = reports.filter(
      (report) =>
        report.type?.id === selectedType &&
        report.date &&
        dayjs(report.date).year() === selectedDate.year()
    );
    setFilteredReports(filteredReports);
  };

  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return true;
    }
  };

  useEffect(() => {
    filterReports();
  }, [selectedType, selectedDate]);

  const checkToken = async () => {
    if (!powerBiToken) {
      const savedToken = readCookie("powerBiToken");
      if (savedToken) {
        if (isTokenExpired(savedToken)) {
          getPowerBiToken();
        } else {
          setPowerBiToken(savedToken);
        }
      } else {
        getPowerBiToken();
      }
    } else {
      if (isTokenExpired(powerBiToken)) {
        getPowerBiToken();
      }
    }
  };

  useEffect(() => {
    getReportTypes();
    if (reports.length === 0) {
      getReports();
    }
  }, []);

  const savePowerBiTokenAsCookie = (token) => {
    const decodedToken = jwtDecode(token);
    const expirationTime = new Date(decodedToken.exp * 1000);
    Cookies.set("powerBiToken", token, { expires: expirationTime });
  };

  const readCookie = (key) => {
    return Cookies.get(key);
  };

  const doMicrosoftLogin = async () => {
    try {
      const accessTokenRequest = {
        scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
      };
      msalInstance
        .loginPopup(accessTokenRequest)
        .then((accessTokenResponse) => {
          setPowerBiToken(accessTokenResponse.accessToken);
          setPowerBIAuthError(false);
          savePowerBiTokenAsCookie(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          console.error(error);
          if (error.errorMessage) {
            messageApi.error(
              "Es ist ein Fehler bei der Authentifizierung gegenüber Power BI aufgetreten. Bitte aktualisiere die Seite"
            );
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getPowerBiToken = async () => {
    const accessTokenRequest = {
      scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
    };
    try {
      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          setPowerBIAuthError(false);
          setPowerBiToken(accessTokenResponse.accessToken);
          savePowerBiTokenAsCookie(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          console.error(error);
          setPowerBIAuthError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onReportChange = (value, record) => {
    checkToken();
    setSelectedReport(record);
  };

  const onReportTypeChange = (value) => {
    myForm.resetFields(["reportID"]);
    setSelectedType(value);
  };

  const onDateChange = (date, dateString) => {
    myForm.resetFields(["reportID"]);
    setSelectedDate(date);
  };
  const collapseItems = [
    {
      key: "1",
      label: "Auswahl",
      children: (
        <Form
          size="large"
          form={myForm}
          id="myForm"
          layout="vertical"
          style={{ margin: 15 }}
          initialValues={{ date: selectedDate, reportID: null }}
        >
          <Form.Item name="type" label="Kategorie">
            <Select
              autoFocus={true}
              placeholder={labels.controlling.powerBI.reportTypeSelect}
              onChange={onReportTypeChange}
              style={{
                width: 320,
              }}
              options={reportTypes}
            />
          </Form.Item>
          <Form.Item name="date" label="Zeitraum">
            <DatePicker
              picker="year"
              placeholder={labels.controlling.powerBI.dateSelect}
              locale={locale}
              format={"YYYY"}
              onChange={onDateChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="reportID" label="Dashboard">
            <Select
              autoFocus={true}
              showSearch
              disabled={!selectedType || !selectedDate}
              placeholder={labels.controlling.powerBI.reportSelect}
              optionFilterProp="children"
              onChange={onReportChange}
              style={{
                width: 320,
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={filteredReports}
            />
          </Form.Item>
          {selectedReport && selectedDate && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                onClick={() => setOpenModal(true)}
                style={{ fontSize: 18, color: "#4ab8a8" }}
              >
                Informationen zum Dashboard
              </a>
              <HelpOutlineOutlinedIcon
                style={{
                  marginLeft: 5,
                  fontSize: 25,
                  color: "#4ab8a8",
                }}
              />
            </div>
          )}
        </Form>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {contextHolder}
        <Collapse
          defaultActiveKey={["1"]}
          style={{ flex: "0 0 auto", marginRight: 10 }}
          items={collapseItems}
        ></Collapse>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            border: "1px solid #d9d9d9",
            borderRadius: 5,
          }}
        >
          {selectedReport && powerBiToken && !PowerBIAuthError ? (
            <PowerBIEmbed
              key={selectedReport?.report_id}
              embedConfig={{
                type: "report",
                id: selectedReport?.report_id,
                embedUrl: PowerBiReports[selectedReport],
                accessToken: powerBiToken,
                tokenType: models.TokenType.Aad,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                },
              }}
              cssClassName="power_bi_container"
            />
          ) : PowerBIAuthError ? (
            <Result
              status="warning"
              title="Fehler bei der Authentifizierung"
              subTitle={
                <>
                  <p>
                    Sorry, die automatische Authentifizierung gegenüber Power BI
                    ist fehlgeschlagen :(
                  </p>
                  <p>Bitte melde dich manuell an, um die Dashboards zu sehen</p>
                </>
              }
              extra={[
                <Button
                  key="console"
                  onClick={doMicrosoftLogin}
                  className="btn-primary"
                >
                  Manuell anmelden
                </Button>,
              ]}
            />
          ) : (
            <Empty description="Bitte wähle einen Bericht aus" />
          )}
        </div>
      </div>
      <Modal
        title={selectedReport?.name}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        width="auto" // Set the width to auto
        style={{ maxWidth: "50%" }} // Ensure the modal does not exceed 90% of the viewport width
        footer={[
          <Button
            key="back"
            onClick={() => setOpenModal(false)}
            className="btn-primary"
          >
            Schließen
          </Button>,
        ]}
      >
        <p style={{ whiteSpace: "pre-wrap", fontSize: 20 }}>
          {selectedReport?.description}
        </p>
      </Modal>
    </>
  );
};

export default PowerBI;
