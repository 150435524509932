import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  Popconfirm,
} from "antd";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuthContext } from "../auth/AuthContext";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import useAuthorized from "../auth/useAuthorized";
const { Text, Title } = Typography;

const SoftwareAccounts = () => {
  const [retrievingData, setRetrievingData] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [rows, setRows] = useState([]);
  const { currentTenant } = useAuthContext();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Lizenz",
      dataIndex: ["license", "name"],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.license.name.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.license.name.localeCompare(b.license.name),
    },
    {
      title: "Benutzer",
      dataIndex: ["account", "email"],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.account.email.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.account.email.localeCompare(b.account.email),
    },
    {
      title: "System-ID",
      dataIndex: "id_software",
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.id_software.localeCompare(b.id_software),
    },
    {
      title: "Abzurechnende Firma",
      dataIndex: ["billing_company", "name"],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) =>
        record.billing_company.name.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) =>
        a.billing_company.name.localeCompare(b.billing_company.name),
    },
  ];

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
  };
  const handleLicenseChange = (value) => {
    setSelectedLicense(value); 
  };

  const getSystems = async () => {
    setRetrievingData(true);
    if (selectedCompany) {
      try {
        let response = await api.get("systems", {
          params: { company: selectedCompany },
        });
        let data = await response.data;
        setLicenses(data);
      } catch (error) {
        messageApi.error("Fehler beim Laden der Daten");
      }
    }
    setRetrievingData(false);
  };
  const determineCompanies = async () => {
    try {
      let companySelectOptions = [];
      if (scope === "msps") {
        companySelectOptions =
          currentTenant?.managed_msps?.map((obj) => ({
            value: obj.id,
            label: obj.name,
          })) || [];
      } else if (scope === "customers") {
        companySelectOptions = currentTenant?.managed_companies?.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
      }
      setCompanies(companySelectOptions);
    } catch (error) {
      console.log(error);
      messageApi.error("Fehler beim Laden der Daten");
      setRetrievingData(false);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      getSystems();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedLicense && selectedCompany) {
      getData();
    }
  }, [selectedCompany, selectedLicense]);

  useEffect(() => {
    form.resetFields();
    setSelectedCompany(null);
    setSelectedLicense(null);
    setRows([]);
    if (scope === "own") {
      setSelectedCompany(currentTenant.own_company.id);
    } else {
      determineCompanies();
    }
  }, [currentTenant, scope]);

  const getData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.get("software-accounts", {
        params: { system: selectedLicense, company: selectedCompany },
      });
      let data = await response.data;
      setRows(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setRetrievingData(false);
  };

  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          dataSource={rows}
          bordered
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {labels.licenses.softwareAccounts[scope].title}
              </Title>
              <Form
                size="large"
                form={form}
                style={{
                  margin: 10,
                }}
              >
                <Space direction="horizontal">
                  <Form.Item name="company">
                    {scope !== "own" && (
                      <Select
                        autoFocus={true}
                        showSearch
                        placeholder={
                          labels.licenses.softwareAccounts[scope].placeholder
                        }
                        optionFilterProp="children"
                        onChange={handleCompanyChange}
                        style={{
                          width: 320,
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={companies}
                      />
                    )}
                  </Form.Item>
                  <Form.Item name="license">
                    <Select
                      autoFocus={true}
                      showSearch
                      disabled={selectedCompany ? false : true}
                      placeholder="Wähle eine Software aus"
                      optionFilterProp="children"
                      onChange={handleLicenseChange}
                      style={{
                        width: 320,
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={licenses}
                    />
                  </Form.Item>
                  {contextHolder}
                </Space>
              </Form>
            </div>
          )}
          pagination={{
            total: rows.length || 0,
            showTotal: (total) => `${total} Objekte`,
            defaultPageSize: 10,
            defaultCurrent: 1,
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
      </div>
    </>
  );
};

export default SoftwareAccounts;
