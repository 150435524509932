import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuthContext } from "./auth/AuthContext";
import MainMenu from "./components/MainMenu";
import MyRoutes from "./auth/MyRoutes";
function App() {
  return (
    <Router>
      <MainMenu>
        <MyRoutes />
      </MainMenu>
    </Router>
  );
}

export default App;
