import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import AuthenticationService from "./auth/AuthenticationService.jsx";
import { ProvisioningDataProvider } from "./store/context";
import { useAuthContext, AuthProvider } from "./auth/AuthContext";
const renderApp = () =>
  ReactDOM.createRoot(document.getElementById("root")).render(
    <AuthProvider>
      <ProvisioningDataProvider>
        <App />
      </ProvisioningDataProvider>
    </AuthProvider>
  );

AuthenticationService.initKeycloak(renderApp);
