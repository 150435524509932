import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Modal,
  Typography,
  Popconfirm,
  DatePicker,
  Popover,
  Divider,
  Tabs,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import useAuthorized from "../auth/useAuthorized";
import dayjs from "dayjs";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import ReportIcon from "@mui/icons-material/Report";
import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import { render } from "@testing-library/react";
import { Tab } from "bootstrap";
const { Text, Title } = Typography;

const Devicereport = () => {
  const [rows, setRows] = useState([]);
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [customerQuantity, setCustomerQuantity] = useState(0);
  const [errorQuantity, setErrorQuantity] = useState(0);

  const getData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.get("customers/data/devices", {
        params: {
          msp: company.own_msp.id,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });
      setRows(response.data);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der Daten");
    }
    setRetrievingData(false);
  };

  useEffect(() => {
    if (currentTenant) {
      if (scope == "customers") {
        setCompany(currentTenant.own_company);
      } else if (scope == "msps") {
        const options = currentTenant?.managed_msps;
        setCompanies(options);
      }
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (company && selectedDate) {
      getData();
    }
  }, [company, selectedDate]);

  const showModalData = (record) => {
    setModalData(record);
    setModalTitle("Details Geräteprüfung " + record.company);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData([]);
    setModalTitle("");
    setModalSubTitle("");
  };

  const columns = [
    {
      title: "Kunde",
      dataIndex: "company",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.company.localeCompare(b.company),
      defaultSortOrder: "ascend",
    },
    {
      title: "Inaktive Geräte",
      dataIndex: "inactive_devices",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.inactive_devices.length - b.inactive_devices.length,
      render: (value) => {
        return value?.length;
      },
    },
    {
      title: "Aktive Geräte ohne Vertrag",
      dataIndex: "active_devices_not_assigned_to_contract",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) =>
        a.active_devices_not_assigned_to_contract.length -
        b.active_devices_not_assigned_to_contract.length,
      render: (value) => {
        return value?.length === 0 ? (
          value?.length
        ) : (
          <strong>
            <span style={{ color: "red" }}>{value?.length}</span>
          </strong>
        );
      },
    },
    {
      title: "Inaktive Geräte Vertrag zugewiesen",
      dataIndex: "inactive_devices_assigned_to_contract",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) =>
        a.inactive_devices_assigned_to_contract.length -
        b.inactive_devices_assigned_to_contract.length,
      render: (value) => {
        return value?.length === 0 ? (
          value?.length
        ) : (
          <strong>
            <span style={{ color: "red" }}>{value?.length}</span>
          </strong>
        );
      },
    },
    {
      title: "Aktive Geräte veraltetem Vertrag zugewiesen",
      dataIndex: "active_devices_assigned_to_deprecated_contract",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) =>
        a.active_devices_assigned_to_deprecated_contract.length -
        b.active_devices_assigned_to_deprecated_contract.length,
      render: (value) => {
        return value?.length === 0 ? (
          value?.length
        ) : (
          <strong>
            <span style={{ color: "red" }}>{value?.length}</span>
          </strong>
        );
      },
    },
    {
      title: "Details",
      key: "details",
      render: (_, record) => (
        <a onClick={() => showModalData(record)}>Details</a>
      ),
    },
    {
      title: "Aktualisiert",
      dataIndex: "updated",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.ad_data?.updated.localeCompare(b.ad_data?.updated),
      render: (value, record) => {
        return value
          ? dayjs(value).format("DD.MM.YYYY HH:mm")
          : record.ad_data
            ? dayjs(record.ad_data.date).format("DD.MM.YYYY HH:mm")
            : "N/A";
      },
    },
  ];

  const handleMSPChange = (key, value) => {
    setCompany(value);
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const TabTable = (dataType) => {
    const tableRows = modalData[dataType.dataType]?.map((row) => row);
    const modalColumns = [
      {
        title: "Gerätename",
        dataIndex: "device_name",
      },
      {
        title: "Gerätetyp",
        dataIndex: "device_type",
      },
    ];
    if (dataType.dataType !== "active_devices_not_assigned_to_contract") {
      modalColumns.push({
        title: "Vertragsname",
        dataIndex: "contract_name",
        render: (value) => {
          return value ? value : "N/A";
        },
      });
    }
    return (
      <Table
        columns={modalColumns}
        rowKey="device_name"
        dataSource={tableRows}
        pagination={{
          total: tableRows.length || 0,
          showTotal: (total) => `${total} Objekte`,
          defaultPageSize: 10,
          defaultCurrent: 1,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        scroll={{
          y: 500,
        }}
        loading={{
          spinning: retrievingData,
          indicator: <LoadingOutlined className="spinner-style" />,
        }}
        bordered
      />
    );
  };

  const tabItems = [
    {
      key: "active_devices_not_assigned_to_contract",
      label: "Aktive Geräte ohne Vertrag",
      children: <TabTable dataType="active_devices_not_assigned_to_contract" />,
    },
    {
      key: "inactive_devices_assigned_to_contract",
      label: "Inaktive Geräte aktivem Vertrag zugewiesen",
      children: <TabTable dataType="inactive_devices_assigned_to_contract" />,
    },
    {
      key: "active_devices_assigned_to_deprecated_contract",
      label: "Aktive Geräte veraltetem Vertrag zugewiesen",
      children: (
        <TabTable dataType="active_devices_assigned_to_deprecated_contract" />
      ),
    },
  ];
  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={rows}
          scroll={{
            y: 500,
          }}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          bordered
          pagination={false}
          title={() => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title
                  level={3}
                  style={{
                    marginTop: -10,
                    marginBottom: 10,
                  }}
                >
                  {labels.reports[scope].deviceReports.title}
                </Title>
                <Form
                  size="large"
                  style={{
                    margin: 10,
                  }}
                >
                  <Form.Item>
                    <Space direction="horizontal">
                      {scope !== "customers" && (
                        <Select
                          autoFocus={true}
                          showSearch
                          placeholder={
                            labels.reports[scope].contractChecks.placeholder
                              .companies
                          }
                          optionFilterProp="children"
                          onChange={handleMSPChange}
                          style={{
                            width: 320,
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={companies}
                        />
                      )}
                      <DatePicker
                        onChange={onDateChange}
                        picker="month"
                        locale={locale}
                        format={"MMMM YYYY"}
                        style={{ width: 200 }}
                        value={selectedDate}
                      />
                      {contextHolder}
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        />
      </div>

      <Modal
        title="Details Geräteprüfung"
        open={showModal}
        onCancel={closeModal}
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => closeModal()}
            className="btn-primary"
          >
            Schließen
          </Button>,
        ]}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "15%",
              }}
            >
              <label>Kunde:</label>
              <label>Aktive Geräte:</label>
              <label>Inaktive Geräte:</label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <span>
                <strong>{modalData?.company}</strong>
              </span>
              <span>{modalData?.active_devices?.length}</span>
              <span>{modalData?.inactive_devices?.length}</span>
            </div>
          </div>
          <Divider />
          <Tabs items={tabItems} />
        </div>
      </Modal>
    </>
  );
};

export default Devicereport;
